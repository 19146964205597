import { useQuery } from '@apollo/client'
import styled from '@emotion/styled'
import { Grid2 } from '@mui/material'
import { memo } from 'react'
import Apex_Chart from 'react-apexcharts'

import { use_workspace_and_org_ids } from '../../../navigation/hooks/use_workspace_and_org_ids'
import { use_app_dispatch, use_app_selector } from '../../../state/redux/hooks'
import { select_staged_dataset_table_rows } from '../../insights_slice'
import { AGGREGATION_TYPE_NONE, OnAxesRangeChange } from '../chart_options'
import { Chart_Legend } from '../legends/Chart_Legend'
import { Centered_Loading_Overlay } from '../loading/Centered_Loading_Overlay'
import {
  select_apex_chart_options,
  select_chart_data,
  select_chart_type,
  select_filters_for_query,
  select_normalize_by_arg,
  select_selected_aggregate_by_property,
  select_selected_group_by_property,
  select_selected_x_cycle_summaries_property,
  select_selected_y_cycle_summaries_properties,
  select_series_groups,
  select_series_groups_aggregate_by_none,
  select_y_axes,
  set_cycle_summaries,
  set_y_property_ranges
} from './cycle_summaries_chart_slice'
import { GET_CYCLE_SUMMARIES } from './queries/get_cycle_summaries'

const Apex_Chart_Controller = memo(() => {
  const dispatch = use_app_dispatch()

  const handle_axes_range_update = ({
    x_axis_range,
    y_axes_ranges,
    x_axis_type,
    y_axes_types
  }: OnAxesRangeChange) => {
    const _new_y_property_ranges: {
      [key: number]: [number | null, number | null]
    } = {}
    Object.keys(y_axes_ranges).forEach(axisIndex => {
      const range = y_axes_ranges[Number(axisIndex)]

      _new_y_property_ranges[Number(axisIndex)] = range
    })

    dispatch(set_y_property_ranges(_new_y_property_ranges))
  }

  const { options, type } = use_app_selector(state =>
    select_apex_chart_options(state, handle_axes_range_update)
  )

  const chart_data = use_app_selector(select_chart_data)

  return (
    <div className='apex_chart_controller'>
      <Apex_Chart
        key={type}
        options={options}
        type={type}
        series={chart_data || []}
        height='440px'
        width='100%'
      />
    </div>
  )
})

export const Cycle_Summaries_Chart = () => {
  const dispatch = use_app_dispatch()
  const staged_dataset_table_rows = use_app_selector(
    select_staged_dataset_table_rows
  )

  const staged_dataset_ids = staged_dataset_table_rows.map(row => row.id)
  const selected_x_cycle_summaries_property = use_app_selector(
    select_selected_x_cycle_summaries_property
  )
  const selected_y_cycle_summaries_properties = use_app_selector(
    select_selected_y_cycle_summaries_properties
  )
  const selected_group_by_property = use_app_selector(
    select_selected_group_by_property
  )
  const selected_aggregate_by_property = use_app_selector(
    select_selected_aggregate_by_property
  )
  const is_aggregate_by_none =
    selected_group_by_property !== null &&
    selected_aggregate_by_property === AGGREGATION_TYPE_NONE

  const selected_normalize_by_arg = use_app_selector(select_normalize_by_arg)

  const series_groups_default = use_app_selector(select_series_groups)
  const series_groups_aggregate_by_none = use_app_selector(
    select_series_groups_aggregate_by_none
  )

  const y_axes = use_app_selector(select_y_axes)
  const chart_type = use_app_selector(select_chart_type)

  const { organization_id, workspace_id } = use_workspace_and_org_ids()

  const filters = use_app_selector(select_filters_for_query)

  const { loading } = useQuery(GET_CYCLE_SUMMARIES, {
    variables: {
      dataset_ids: staged_dataset_ids,
      organization_id: organization_id!,
      workspace_ids: [workspace_id!],
      dataset_filters: filters,
      y_properties: selected_y_cycle_summaries_properties.map(
        property => property.key
      ),
      x_property: selected_x_cycle_summaries_property.key,
      group_by_property: selected_group_by_property?.key,
      aggregate_by: selected_aggregate_by_property.key,
      normalize_by: selected_normalize_by_arg
    },
    onCompleted(data) {
      dispatch(set_cycle_summaries(data.get_cycle_summaries))
    },
    skip: !organization_id || !workspace_id
  })

  // If aggregation is set to None, override the UI on the chart legend with series_groups_aggregate_by_none
  const series_groups = is_aggregate_by_none
    ? series_groups_aggregate_by_none.series_groups
    : series_groups_default

  return (
    <Cycler_Time_Series_Chart_Container container>
      <Chart_Legend
        series_groups={series_groups}
        y_axes={y_axes}
        chart_type={chart_type}
      />
      <Grid2 size={12}>
        {loading && <Centered_Loading_Overlay />}
        <Apex_Chart_Controller />
      </Grid2>
    </Cycler_Time_Series_Chart_Container>
  )
}

const Cycler_Time_Series_Chart_Container = styled(Grid2)`
  .apex_chart_controller {
    display: block;
    width: 100%;
    padding: 0;
  }
`
