import {
  Filter_Option,
  Filter_Type,
  Property_Type
} from '../gql_generated/graphql'

export interface Selector_Option {
  key: any
  label: string
  group?: string
  type?: string | null
}

export type Property_Filter = {
  filter_id: string
  filter_type?: Filter_Type
  property: Filter_Option | null
}

export type Property_Filter_With_Values = {
  filter_type: Filter_Type
  property: Filter_Option | null
  values: Filter_Option[]
}

export const PLOTTABLE_PROPERTY_TYPES = [
  Property_Type.Float,
  Property_Type.Long
]
