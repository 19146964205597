import { Menu_Nav_Provider, Menu_Panel } from '../../../controls/menus'
import { Menu_Panel_Id } from '../../../controls/menus/constants'
import { Menu_Nav_Props } from '../../../controls/menus/types'
import { In_Cycle_Panel_Cycle_Filters } from './In_Cycle_Panel_Cycle_Filters'
import { In_Cycle_Panel_In_Cycle_Filters } from './In_Cycle_Panel_In_Cycle_Filters'
import { In_Cycle_Panel_Root } from './In_Cycle_Panel_Root'
import { In_Cycle_Panel_X_Axis } from './In_Cycle_Panel_X_Axis'
import { In_Cycle_Panel_Y_Axis } from './In_Cycle_Panel_Y_Axis'

export function In_Cycle_Chart_Menu_Nav(props: Menu_Nav_Props) {
  const { chart_title = 'In-Cycle Chart', popover_ref } = props

  return (
    <Menu_Nav_Provider on_panel_change={popover_ref?.current?.updatePosition}>
      <Menu_Panel panel_id={Menu_Panel_Id.ROOT}>
        <In_Cycle_Panel_Root chart_title={chart_title} />
      </Menu_Panel>
      <Menu_Panel panel_id={Menu_Panel_Id.X_AXIS}>
        <In_Cycle_Panel_X_Axis />
      </Menu_Panel>
      <Menu_Panel panel_id={Menu_Panel_Id.Y_AXIS}>
        <In_Cycle_Panel_Y_Axis />
      </Menu_Panel>
      <Menu_Panel panel_id={Menu_Panel_Id.IN_CYCLE_FILTERS}>
        <In_Cycle_Panel_In_Cycle_Filters />
      </Menu_Panel>
      <Menu_Panel panel_id={Menu_Panel_Id.CYCLE_FILTERS}>
        <In_Cycle_Panel_Cycle_Filters />
      </Menu_Panel>
    </Menu_Nav_Provider>
  )
}
