import { useQuery } from '@apollo/client'
import { Box, Button, Grid2 } from '@mui/material'
import { Plus, Trash03 } from '@untitled-ui/icons-react'
import { Fragment } from 'react'

import { Filter_Option } from '../../../../gql_generated/graphql'
import { use_workspace_and_org_ids } from '../../../../navigation/hooks/use_workspace_and_org_ids'
import {
  use_app_dispatch,
  use_app_selector
} from '../../../../state/redux/hooks'
import { Autocomplete_Filter } from '../../../controls/filters/Autocomplete_Filter'
import Numeric_Filter from '../../../controls/filters/Numeric_Filter'
import { Menu_Header, Menu_Item_Control } from '../../../controls/menus'
import { use_menu_nav } from '../../../controls/menus/Menu_Nav_Context'
import { Selector_Option } from '../../../types'
import {
  select_plottable_property_options,
  select_selected_y_cycle_summaries_properties,
  select_y_property_ranges,
  set_property_options,
  set_selected_y_cycle_summaries_properties,
  set_y_property_ranges
} from '../cycle_summaries_chart_slice'
import { GET_CYCLE_SUMMARY_PROPERTIES } from '../queries/get_cycle_summary_properties'

const MAX_Y_AXIS_METRICS = 2

export function Cycle_Panel_Y_Axis() {
  const { return_to_root } = use_menu_nav()
  const { organization_id, workspace_id } = use_workspace_and_org_ids()
  const dispatch = use_app_dispatch()

  const selected_y_cycle_summaries_properties = use_app_selector(
    select_selected_y_cycle_summaries_properties
  )
  const selected_y_property_ranges = use_app_selector(select_y_property_ranges)

  const plottable_property_options = use_app_selector(
    select_plottable_property_options
  )

  function handle_selected_y_cycle_summaries_properties_change(
    selected_cycle_summaries_properties: Selector_Option[]
  ) {
    dispatch(
      set_selected_y_cycle_summaries_properties(
        selected_cycle_summaries_properties
      )
    )
  }

  const handle_add_property_filter = () => {
    handle_selected_y_cycle_summaries_properties_change(
      selected_y_cycle_summaries_properties.concat({ key: '', label: '' })
    )
  }

  const handle_remove_property_filter = (index: number) => {
    handle_selected_y_cycle_summaries_properties_change(
      selected_y_cycle_summaries_properties.filter((_filter, i) => i !== index)
    )
  }

  const handle_set_property_filter =
    (index: number) => (_e: any, value: Filter_Option) => {
      handle_selected_y_cycle_summaries_properties_change(
        selected_y_cycle_summaries_properties.map((filter, i) =>
          i === index ? value : filter
        )
      )
    }

  function handle_y_property_range_change(
    index: number,
    new_range: [number | null, number | null]
  ) {
    const _newRanges = { ...selected_y_property_ranges }
    _newRanges[index] = new_range

    dispatch(set_y_property_ranges(_newRanges))
  }

  const { loading } = useQuery(GET_CYCLE_SUMMARY_PROPERTIES, {
    variables: {
      organization_id: organization_id as string
    },
    skip: !organization_id || !workspace_id,
    onCompleted: data => {
      dispatch(
        set_property_options({
          cycle_summary_properties:
            data.get_cycle_summary_properties.cycle_summary_properties,
          dataset_properties:
            data.get_cycle_summary_properties.dataset_properties
        })
      )
    }
  })

  return (
    <>
      <Menu_Header title='Y-Axis' on_go_back={return_to_root} />
      <Box width={400} paddingBottom={2} paddingInline={1}>
        {selected_y_cycle_summaries_properties.map((filter_option, index) => {
          const selected_y_property_range = selected_y_property_ranges?.[index]

          return (
            <Fragment key={filter_option.key}>
              <Grid2 container paddingBlock={1} paddingInline={1} rowGap={1}>
                <Menu_Item_Control
                  input_id={`y-axis-${index}-type`}
                  label={`Y-Axis ${index + 1}`}
                  emphasize
                >
                  <Autocomplete_Filter
                    id={`y-axis-${index}-type`}
                    aria-labelledby={`y-axis-${index}-type`}
                    disableClearable
                    grid_item_size={7}
                    loading={loading}
                    multiple={false}
                    onChange={handle_set_property_filter(index)}
                    options={plottable_property_options}
                    placeholder='Add Metric'
                    value={filter_option}
                    getOptionDisabled={
                      selected_y_cycle_summaries_properties.length > 1
                        ? (option: Filter_Option) =>
                            selected_y_cycle_summaries_properties.some(
                              filter => filter.key === option.key
                            )
                        : undefined
                    }
                  />
                </Menu_Item_Control>
                <Menu_Item_Control
                  input_id={`y-axis-${index}-normalize-by`}
                  label='Normalize by'
                >
                  <Autocomplete_Filter
                    id={`y-axis-${index}-normalize-by`}
                    aria-labelledby={`y-axis-${index}-normalize-by`}
                    disableClearable={true}
                    disabled
                    grid_item_size={7}
                    loading={loading}
                    multiple={false}
                    // onChange={handle_selected_x_observation_property_change}
                    options={[]}
                    placeholder='Add Metric'
                    value={undefined}
                  />
                </Menu_Item_Control>
                <Menu_Item_Control
                  input_id={`y-axis-${index}-limits`}
                  label='Limits (Min - Max)'
                >
                  <Grid2
                    flexDirection='row'
                    columnGap={1}
                    display='inline-flex'
                    size={7}
                  >
                    <Numeric_Filter
                      placeholder='Min'
                      onBlur={event => {
                        const _parsedValue = parseFloat(event.target.value)

                        handle_y_property_range_change(index, [
                          _parsedValue || null,
                          selected_y_property_range?.[1] || null
                        ])
                      }}
                      value={selected_y_property_range?.[0] ?? undefined}
                    />

                    <Numeric_Filter
                      placeholder='Max'
                      onBlur={event => {
                        const _parsedValue = parseFloat(event.target.value)

                        if (!isNaN(_parsedValue)) {
                          handle_y_property_range_change(index, [
                            selected_y_property_range?.[0] || 0,
                            _parsedValue
                          ])
                        }
                      }}
                      value={selected_y_property_range?.[1] ?? undefined}
                    />
                  </Grid2>
                </Menu_Item_Control>
              </Grid2>
              {selected_y_cycle_summaries_properties.length > 1 && (
                <Grid2
                  display='flex'
                  size='grow'
                  justifyContent='flex-end'
                  paddingInline={1}
                >
                  <Button
                    variant='text'
                    size='small'
                    startIcon={<Trash03 width={16} />}
                    onClick={() => handle_remove_property_filter(index)}
                  >
                    Remove Y-Axis
                  </Button>
                </Grid2>
              )}
            </Fragment>
          )
        })}
        {selected_y_cycle_summaries_properties.length < MAX_Y_AXIS_METRICS && (
          <Button
            variant='text'
            size='small'
            startIcon={<Plus width={16} />}
            onClick={handle_add_property_filter}
          >
            Add Y-Axis
          </Button>
        )}
      </Box>
    </>
  )
}
