import { Grid2, styled } from '@mui/material'
import { memo } from 'react'
import Apex_Chart from 'react-apexcharts'

import { use_app_selector } from '../../../state/redux/hooks'
import { CHART_TYPE } from '../chart_options'
import { Chart_Legend } from '../legends/Chart_Legend'
import {
  select_apex_chart_options,
  select_chart_data,
  select_series_groups,
  select_y_axes
} from './dataset_metrics_chart_slice'

const Apex_Chart_Controller = memo(() => {
  const { options } = use_app_selector(select_apex_chart_options)
  const chart_data = use_app_selector(select_chart_data)

  return (
    <div className='apex_chart_controller'>
      <Apex_Chart
        options={options}
        type='scatter'
        series={chart_data || []}
        height='440px'
        width='100%'
      />
    </div>
  )
})

export const Dataset_Metrics_Chart = () => {
  const series_groups = use_app_selector(select_series_groups)
  const y_axes = use_app_selector(select_y_axes)
  return (
    <Chart_Container container>
      <Chart_Legend
        series_groups={series_groups}
        y_axes={y_axes}
        chart_type={CHART_TYPE.SCATTER}
      />
      <Grid2 size={12}>
        <Apex_Chart_Controller />
      </Grid2>
    </Chart_Container>
  )
}

const Chart_Container = styled(Grid2)`
  .apex_chart_controller {
    display: block;
    width: 100%;
    padding: 0;
  }

  .apexcharts-xaxis-label {
    padding: 3rem;
  }
`
