import { useQuery } from '@apollo/client'
import { Box, Grid2 } from '@mui/material'

import { Filter_Option } from '../../../../gql_generated/graphql'
import { use_workspace_and_org_ids } from '../../../../navigation/hooks/use_workspace_and_org_ids'
import {
  use_app_dispatch,
  use_app_selector
} from '../../../../state/redux/hooks'
import { Autocomplete_Filter } from '../../../controls/filters/Autocomplete_Filter'
import {
  Menu_Header,
  Menu_Item_Control,
  use_menu_nav
} from '../../../controls/menus'
import { select_staged_dataset_table_rows } from '../../../insights_slice'
import {
  select_property_filter_keys,
  select_selected_cycles,
  set_selected_cycles
} from '../cycler_observations_chart_slice'
import { GET_CYCLE_OBSERVATION_FILTER_CONFIGS } from '../queries/get_cycler_observation_filter_configs'

export function In_Cycle_Panel_Cycle_Filters() {
  const { return_to_root } = use_menu_nav()
  const dispatch = use_app_dispatch()
  const { organization_id, workspace_id } = use_workspace_and_org_ids()

  const additional_filter_keys = use_app_selector(select_property_filter_keys)

  const staged_dataset_table_rows = use_app_selector(
    select_staged_dataset_table_rows
  )
  const { data, loading } = useQuery(GET_CYCLE_OBSERVATION_FILTER_CONFIGS, {
    variables: {
      organization_id: organization_id as string,
      workspace_ids: [workspace_id as string],
      dataset_ids: (staged_dataset_table_rows || []).map(row => row.id),
      dataset_filters: [],
      additional_filter_keys
    },
    skip: !organization_id || !workspace_id
  })

  const selected_cycles = use_app_selector(select_selected_cycles)

  function handle_selected_cycles_change(
    _e: any,
    selected_cycle_numbers: Filter_Option[]
  ) {
    dispatch(set_selected_cycles(selected_cycle_numbers))
  }

  const cycle_options =
    data?.get_observation_filter_configs?.find(
      property => property.filter_property === 'cycle_number'
    )?.options || []

  return (
    <>
      <Menu_Header title='Cycle Filters' on_go_back={return_to_root} />
      <Box width={400} paddingBottom={2}>
        <Grid2 container paddingBlock={1} paddingInline={1} rowGap={1}>
          <Menu_Item_Control
            label='Cycle Number'
            input_id='cycle-number'
            emphasize
          >
            <Autocomplete_Filter
              id='cycle-number'
              grid_item_size='grow'
              value={selected_cycles}
              onChange={handle_selected_cycles_change}
              multiple={true}
              options={cycle_options}
              placeholder='Add Cycle'
              loading={loading}
            />
          </Menu_Item_Control>
        </Grid2>
      </Box>
    </>
  )
}
