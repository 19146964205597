import {
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements
} from 'react-router-dom'

import { Insight_Dashboard } from '../insights/Insight_Dashboard'
import { Error_Fallback } from './Error_Fallback'
import { Navigation_Root } from './Navigation_Root'

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path='/'
      element={<Navigation_Root />}
      errorElement={<Error_Fallback />}
    >
      <Route path=':organization_key'>
        <Route path=':workspace_key' element={<Outlet />}>
          <Route index element={<Navigate to='reports' />} />
          <Route path='home' element={<>Coming Soon</>} />
          <Route path='reports' element={<Insight_Dashboard />} />
          <Route path='alerts' element={<>Coming Soon</>} />
        </Route>
      </Route>
    </Route>
  )
)

export const Navigation = () => {
  return <RouterProvider router={router} />
}
