import { Menu_Nav_Base } from './Menu_Nav_Context'

export const Menu_Panel_Id = {
  ...Menu_Nav_Base,
  X_AXIS: 'x-axis',
  Y_AXIS: 'y-axis',
  DATASET_FILTERS: 'dataset-filters',
  CYCLE_FILTERS: 'cycle-filters',
  CYCLE_METRICS_FILTERS: 'cycle-metrics-filters',
  IN_CYCLE_FILTERS: 'in-cycle-filters',
  EXPORT: 'export',
  GROUP_BY: 'group-by'
} as const
