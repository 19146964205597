import { Grid2, styled } from '@mui/material'

import { CHART_TYPE } from '../chart_options'
import { Dynamic_Column_Grid } from './Dynamic_Column_Grid'
import { Line_Icon } from './Line_Icon'
import { Marker_Icon } from './Marker_Icon'

interface Chart_Legend_Props {
  series_groups: Record<string, { color: string; label: string }>
  y_axes: Record<
    string,
    { label: string; marker: MarkerShapeOptions; dash_array?: number }
  >
  chart_type: CHART_TYPE
}

export const Chart_Legend = ({
  series_groups,
  y_axes,
  chart_type
}: Chart_Legend_Props) => {
  if (!Object.keys(y_axes).length || !Object.keys(series_groups).length) return
  const y_axes_count = Object.keys(y_axes).length

  return (
    <Legend_Container
      container
      wrap='nowrap'
      flexDirection={'column'}
      paddingRight={`${80 * (y_axes_count - 1) + 40}px`} // ensures legends do not extend past chart with multi y-axes
    >
      <Legend_Section container>
        {Object.keys(y_axes).map(y_axis_key => (
          <Legend_Item_Container
            direction={'row'}
            gap={0.5}
            alignItems={'center'}
            key={y_axis_key}
          >
            {chart_type === CHART_TYPE.LINE ? (
              <Line_Icon dash_array={y_axes[y_axis_key].dash_array || 0} />
            ) : (
              <Marker_Icon marker={y_axes[y_axis_key].marker} />
            )}
            {y_axes[y_axis_key].label}
          </Legend_Item_Container>
        ))}
      </Legend_Section>
      <Dynamic_Column_Grid>
        {Object.keys(series_groups).map(group_key => (
          <Legend_Item_Container
            direction={'row'}
            gap={0.5}
            alignItems={'center'}
            key={group_key}
            className={'legend_item'}
          >
            <Marker_Icon
              marker={'circle'}
              color={series_groups[group_key].color}
            />
            {series_groups[group_key].label}
          </Legend_Item_Container>
        ))}
      </Dynamic_Column_Grid>
    </Legend_Container>
  )
}

const Legend_Container = styled(Grid2)`
  padding-left: 5rem;
  padding-top: 1rem;
  font-size: 12px;
`

const Legend_Section = styled(Grid2)`
  height: fit-content;
  width: fit-content;
  padding-bottom: 0.5rem;
`

const Legend_Item_Container = styled(Grid2)`
  display: flex;
  height: fit-content;
  width: max-content;
  padding-right: 1rem;
`
