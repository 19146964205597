import {
  Grid2,
  Grid2Props,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled
} from '@mui/material'
import { ChevronDown, LineChartUp01 } from '@untitled-ui/icons-react'

import { use_app_dispatch, use_app_selector } from '../../../state/redux/hooks'
import {
  select_active_chart,
  select_charts,
  set_active_chart
} from '../../insights_slice'

export function Chart_Metric_Type_Select() {
  const dispatch = use_app_dispatch()

  const active_chart = use_app_selector(select_active_chart)
  const charts = use_app_selector(select_charts)
  const active_chart_descriptor = charts[active_chart]
  const active_chart_id = active_chart_descriptor.id

  const handle_active_chart_change = (e: SelectChangeEvent) => {
    dispatch(set_active_chart(e.target.value))
  }

  return (
    <Select
      tab-index={0}
      sx={{
        border: 0,
        boxShadow: 'none',
        width: '100%',
        padding: 0,
        '& .MuiSelect-icon': {
          top: 'auto',
          color: theme => theme.palette.gray['500'],
          width: '1em',
          height: '1em'
        },
        '& .MuiSelect-select': {
          paddingInline: theme => theme.spacing(1),
          '&:hover': {
            backgroundColor: theme => theme.palette.grey[100]
          }
        }
      }}
      value={active_chart_id}
      IconComponent={ChevronDown}
      renderValue={value => {
        return (
          <Select_Value>
            <LineChartUp01 width={18} height={18} />
            {`Chart: ${charts[value]?.label}`}
          </Select_Value>
        )
      }}
      onChange={handle_active_chart_change}
      MenuProps={{
        slotProps: { paper: { elevation: 4 } }
      }}
    >
      {Object.entries(charts).map(([key, value]) => (
        <Menu_Item_Label
          key={key}
          value={value.id}
          selected={active_chart_id === value.id}
        >
          Chart: {value.label}
        </Menu_Item_Label>
      ))}
    </Select>
  )
}

const Menu_Item_Label = styled(MenuItem)(({ theme }) => ({
  fontWeight: 500,
  fontSize: theme.typography.sm,
  paddingBlock: theme.spacing(1.25),
  '&.Mui-selected': {
    backgroundColor: theme.palette.grey[100],
    '&:hover,&:focus': {
      backgroundColor: theme.palette.grey[200]
    }
  }
}))

const Select_Value = styled((props: Grid2Props) => (
  <Grid2
    {...props}
    columnGap={1}
    display='flex'
    direction='row'
    alignItems='center'
  />
))(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 700,
  // gap: '0.5rem',
  flex: 1,
  fontSize: theme.typography.sm
}))
