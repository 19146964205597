import { Grid2, styled } from '@mui/material'

import { use_app_selector } from '../../state/redux/hooks'
import {
  Chart_Views,
  select_active_chart,
  select_charts
} from '../insights_slice'
import { Cycle_Summaries_Chart } from './cycle_summaries/Cycle_Summaries_Chart'
import { Cycler_Observations_Chart } from './cycler_observations/Cycler_Observations_Chart'
import { Dataset_Metrics_Chart } from './dataset_metrics/Dataset_Metrics_Chart'

export const Insights_Chart_View = () => {
  const active_chart = use_app_selector(select_active_chart)
  const charts = use_app_selector(select_charts)
  const active_chart_type = charts[active_chart].chart_view

  return (
    <Insights_Chart_View_Container container>
      <Grid2 size={12}>
        <div hidden={active_chart_type !== Chart_Views.CYCLER_SERIES_CHART}>
          <Cycler_Observations_Chart />
        </div>
        <div hidden={active_chart_type !== Chart_Views.CYCLER_CYCLE_CHART}>
          <Cycle_Summaries_Chart />
        </div>
        <div hidden={active_chart_type !== Chart_Views.DATASET_METRICS_CHART}>
          <Dataset_Metrics_Chart />
        </div>
      </Grid2>
    </Insights_Chart_View_Container>
  )
}

const Insights_Chart_View_Container = styled(Grid2)``
