import { Box, Divider, MenuList } from '@mui/material'
import { BarChart12, HorizontalBarChart01 } from '@untitled-ui/icons-react'

import { use_app_selector } from '../../../../state/redux/hooks'
import { Chart_Metric_Type_Select } from '../../../controls/filters/Chart_Metric_Type_Select'
import { Menu_Header, use_menu_nav } from '../../../controls/menus'
import { Menu_Panel_Id } from '../../../controls/menus/constants'
import {
  Menu_Item_Label,
  Menu_Item_Right,
  Section,
  Styled_ChevronRight,
  Styled_Menu_Item
} from '../../../controls/menus/shared_styled_components'
import {
  select_selected_x_property,
  select_selected_y_properties
} from '../dataset_metrics_chart_slice'

type Panel_Root_Props = {
  chart_title: string
}
export function Dataset_Metrics_Panel_Root(props: Panel_Root_Props) {
  // const dispatch = use_app_dispatch()
  const { create_set_selected_panel_id } = use_menu_nav()
  const { chart_title } = props

  // const selected_chart_type = use_app_selector(select_chart_type)
  const selected_x_property = use_app_selector(select_selected_x_property)
  const selected_y_properties = use_app_selector(select_selected_y_properties)

  // function handle_selected_chart_type_change(e: SelectChangeEvent) {
  //   dispatch(set_chart_type(e.target.value as CHART_TYPE))
  // }

  return (
    <Box width={400}>
      <MenuList>
        <Menu_Header title={chart_title} />
        <Chart_Metric_Type_Select />
        <Divider sx={{ marginBlock: 1 }} />
        <Styled_Menu_Item
          onClick={create_set_selected_panel_id(Menu_Panel_Id.X_AXIS)}
        >
          <Menu_Item_Label>
            <BarChart12 width={18} height={18} /> X-Axis
          </Menu_Item_Label>
          <Menu_Item_Right>
            {selected_x_property?.label}
            <Styled_ChevronRight />
          </Menu_Item_Right>
        </Styled_Menu_Item>
        <Section>
          <Styled_Menu_Item
            onClick={create_set_selected_panel_id(Menu_Panel_Id.Y_AXIS)}
          >
            <Menu_Item_Label>
              <HorizontalBarChart01 width={18} height={18} /> Y-Axis
            </Menu_Item_Label>
            <Menu_Item_Right>
              {selected_y_properties.length > 1
                ? `${selected_y_properties.length} Items`
                : selected_y_properties[0]?.label}
              <Styled_ChevronRight />
            </Menu_Item_Right>
          </Styled_Menu_Item>
        </Section>
        {/* <Divider />
        <Section>
          <Styled_Menu_Item
            onClick={create_set_selected_panel_id(Menu_Panel_Id.EXPORT)}
          >
            <Menu_Item_Label>
              <Share03 width={18} height={18} /> Export
            </Menu_Item_Label>
            <Styled_ChevronRight />
          </Styled_Menu_Item>
        </Section> */}
      </MenuList>
    </Box>
  )
}
