import { CircularProgress, styled } from '@mui/material'

export const Centered_Loading_Overlay = () => {
  return (
    <Loading_Wrapper>
      <CircularProgress size={50} />
    </Loading_Wrapper>
  )
}

const Loading_Wrapper = styled('div')`
  position: relative;
  left: 50%;
  top: 45%;
  height: 0px;
  z-index: 1;
`
