import { Grid2, Typography, styled } from '@mui/material'
import { ErrorBoundary } from 'react-error-boundary'

import { use_app_selector } from '../state/redux/hooks'
import { Insights_Chart_View } from './charts/Insights_Chart_View'
import { Insights_Filter_Controls } from './controls/filters/Insights_Filter_Controls'
import { select_active_chart, select_charts } from './insights_slice'
import { Insights_Datasets_Table } from './tables/Insights_Datasets_Table'

export const Insight_Dashboard = () => {
  const active_chart = use_app_selector(select_active_chart)
  const charts = use_app_selector(select_charts)
  const active_chart_descriptor = charts[active_chart]

  return (
    <Dashboard_Container container>
      <Page_Title_Item size={12}>
        <Typography variant='h5' component='h2' sx={{ fontWeight: 600 }}>
          Report: {active_chart_descriptor.label}
        </Typography>
      </Page_Title_Item>
      <Insight_Charts_Wrapper container size={12}>
        <Insights_Chart_Item_Wrapper size={12}>
          <Insights_Chart_Filter_Wrapper size={12}>
            <Insights_Filter_Controls />
          </Insights_Chart_Filter_Wrapper>
          <Grid2 size={12}>
            <Insights_Chart_View />
          </Grid2>
        </Insights_Chart_Item_Wrapper>
      </Insight_Charts_Wrapper>
      <Grid2 size={12}>
        <ErrorBoundary fallback={null}>
          <Insights_Datasets_Table />
        </ErrorBoundary>
      </Grid2>
    </Dashboard_Container>
  )
}

const Dashboard_Container = styled(Grid2)`
  min-width: 800px;
`

const Page_Title_Item = styled(Grid2)`
  margin-top: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
`

const Insight_Charts_Wrapper = styled(Grid2)`
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  margin-block: 1rem;
`

const Insights_Chart_Item_Wrapper = styled(Grid2)`
  padding-top: 1rem;
  padding-left: 0;
  padding-right: 0;
  position: relative;
`

const Insights_Chart_Filter_Wrapper = styled(Grid2)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
`
