import { useQuery } from '@apollo/client'
import { Box, Grid2 } from '@mui/material'

import { Filter_Option } from '../../../../gql_generated/graphql'
import { use_workspace_and_org_ids } from '../../../../navigation/hooks/use_workspace_and_org_ids'
import {
  use_app_dispatch,
  use_app_selector
} from '../../../../state/redux/hooks'
import { Autocomplete_Filter } from '../../../controls/filters/Autocomplete_Filter'
import Numeric_Filter from '../../../controls/filters/Numeric_Filter'
import { Menu_Header, Menu_Item_Control } from '../../../controls/menus'
import { use_menu_nav } from '../../../controls/menus/Menu_Nav_Context'
import {
  Normalize_By_Option,
  select_normalize_by_options,
  select_plottable_property_options,
  select_selected_normalize_by_option,
  select_selected_x_cycle_summaries_property,
  select_x_property_range,
  set_property_options,
  set_selected_normalize_by_option,
  set_selected_x_cycle_summaries_property,
  set_x_property_range
} from '../cycle_summaries_chart_slice'
import { GET_CYCLE_SUMMARY_PROPERTIES } from '../queries/get_cycle_summary_properties'

export function Cycle_Panel_X_Axis() {
  const { return_to_root } = use_menu_nav()
  const { organization_id, workspace_id } = use_workspace_and_org_ids()
  const dispatch = use_app_dispatch()

  const selected_x_cycle_summaries_property = use_app_selector(
    select_selected_x_cycle_summaries_property
  )
  const selected_x_property_range = use_app_selector(select_x_property_range)

  const selected_normalize_by_option = use_app_selector(
    select_selected_normalize_by_option
  )
  const plottable_property_options = use_app_selector(
    select_plottable_property_options
  )
  const normalize_by_options = use_app_selector(select_normalize_by_options)

  function handle_selected_x_cycle_summaries_property_change(
    _e: any,
    selected_cycle_summaries_property: Filter_Option
  ) {
    dispatch(
      set_selected_x_cycle_summaries_property(selected_cycle_summaries_property)
    )
  }

  function handle_selected_normalize_by_option_change(
    _e: any,
    selected_normalize_by_option: Normalize_By_Option | null
  ) {
    dispatch(set_selected_normalize_by_option(selected_normalize_by_option))
  }

  function handle_x_property_range_change(
    new_range: [number | null, number | null]
  ) {
    dispatch(set_x_property_range(new_range))
  }

  const { loading } = useQuery(GET_CYCLE_SUMMARY_PROPERTIES, {
    variables: {
      organization_id: organization_id as string
    },
    skip: !organization_id || !workspace_id,
    onCompleted: data => {
      dispatch(
        set_property_options({
          cycle_summary_properties:
            data.get_cycle_summary_properties.cycle_summary_properties,
          dataset_properties:
            data.get_cycle_summary_properties.dataset_properties
        })
      )
    }
  })

  return (
    <>
      <Menu_Header title='X-Axis' on_go_back={return_to_root} />
      <Box width={400} paddingBottom={2}>
        <Grid2 container paddingBlock={1} paddingInline={1} rowGap={1}>
          <Menu_Item_Control label='X-Axis' input_id='x-axis-type' emphasize>
            <Autocomplete_Filter
              id='x-axis-type'
              aria-labelledby='x-axis-type'
              disableClearable={true}
              grid_item_size={7}
              loading={loading}
              multiple={false}
              onChange={handle_selected_x_cycle_summaries_property_change}
              options={plottable_property_options}
              placeholder='Add Metric'
              value={selected_x_cycle_summaries_property}
            />
          </Menu_Item_Control>
          <Menu_Item_Control
            label='Normalize by'
            input_id='x-axis-normalize-by'
          >
            <Autocomplete_Filter
              id='x-axis-normalize-by'
              aria-labelledby='x-axis-normalize-by'
              disabled
              grid_item_size={7}
              loading={loading}
              multiple={false}
              onChange={handle_selected_normalize_by_option_change}
              options={normalize_by_options}
              placeholder='Add Metric'
              value={selected_normalize_by_option}
            />
          </Menu_Item_Control>
          <Menu_Item_Control
            label='Limits (Min - Max)'
            input_id='x-axis-limits'
          >
            <Grid2
              flexDirection='row'
              columnGap={1}
              display='inline-flex'
              size={7}
            >
              <Numeric_Filter
                placeholder='X-Axis min'
                onBlur={event => {
                  const _parsedValue = parseFloat(event.target.value)

                  handle_x_property_range_change([
                    _parsedValue || null,
                    selected_x_property_range?.[1] || null
                  ])
                }}
                value={selected_x_property_range?.[0] ?? undefined}
              />

              <Numeric_Filter
                placeholder='X-Axis max'
                onBlur={event => {
                  const _parsedValue = parseFloat(event.target.value)

                  handle_x_property_range_change([
                    selected_x_property_range?.[0] || null,
                    _parsedValue || null
                  ])
                }}
                value={selected_x_property_range?.[1] ?? undefined}
              />
            </Grid2>
          </Menu_Item_Control>
        </Grid2>
      </Box>
    </>
  )
}
