import { Box, Button, Grid2, TextField } from '@mui/material'
import { Plus, Trash03 } from '@untitled-ui/icons-react'

import { Filter_Option } from '../../../../gql_generated/graphql'
import {
  use_app_dispatch,
  use_app_selector
} from '../../../../state/redux/hooks'
import { Autocomplete_Filter } from '../../../controls/filters/Autocomplete_Filter'
import {
  Menu_Header,
  Menu_Item_Control,
  use_menu_nav
} from '../../../controls/menus'
import {
  select_metric_options,
  select_selected_y_properties,
  set_selected_y_properties
} from '../dataset_metrics_chart_slice'

const MAX_Y_AXIS_METRICS = 2

export function Dataset_Metrics_Panel_Y_Axis() {
  const { return_to_root } = use_menu_nav()
  const dispatch = use_app_dispatch()

  const metric_options = use_app_selector(select_metric_options)
  const selected_y_properties = use_app_selector(select_selected_y_properties)

  function handle_selected_y_property_change(
    next_selected_y_properties: Filter_Option[]
  ) {
    dispatch(set_selected_y_properties(next_selected_y_properties))
  }

  const handle_add_property_filter = () => {
    handle_selected_y_property_change(
      selected_y_properties.concat({ key: '', label: '' })
    )
  }

  const handle_remove_property_filter = (index: number) => {
    handle_selected_y_property_change(
      selected_y_properties.filter((_filter, i) => i !== index)
    )
  }

  const handle_set_property_filter =
    (index: number) => (_e: any, value: Filter_Option) => {
      handle_selected_y_property_change(
        selected_y_properties.map((filter, i) => (i === index ? value : filter))
      )
    }

  return (
    <>
      <Menu_Header title='Y-Axis' on_go_back={return_to_root} />
      <Box width={400} paddingBottom={2} paddingInline={1}>
        {selected_y_properties.map((filter_option, index) => (
          <>
            <Grid2 container paddingBlock={1} paddingInline={1} rowGap={1}>
              <Menu_Item_Control
                input_id={`y-axis-${index}-type`}
                label='Y-Axis'
                emphasize
              >
                <Autocomplete_Filter
                  id={`y-axis-${index}-type`}
                  aria-labelledby={`y-axis-${index}-type`}
                  disableClearable
                  grid_item_size={7}
                  multiple={false}
                  onChange={handle_set_property_filter(index)}
                  options={metric_options}
                  placeholder='Add Metric'
                  value={filter_option}
                  getOptionDisabled={
                    selected_y_properties.length > 1
                      ? (option: Filter_Option) =>
                          selected_y_properties.some(
                            filter => filter.key === option.key
                          )
                      : undefined
                  }
                />
              </Menu_Item_Control>
              <Menu_Item_Control
                input_id={`y-axis-${index}-normalize-by`}
                label='Normalize by'
              >
                {/** THIS IS A PLACEHOLDER */}
                <Autocomplete_Filter
                  id={`y-axis-${index}-normalize-by`}
                  aria-labelledby={`y-axis-${index}-normalize-by`}
                  disableClearable={true}
                  disabled
                  grid_item_size={7}
                  multiple={false}
                  // onChange={handle_selected_x_observation_property_change}
                  options={[]}
                  placeholder='Add Metric'
                  value={undefined}
                />
              </Menu_Item_Control>
              <Menu_Item_Control
                input_id={`y-axis-${index}-limits`}
                label='Limits (Min - Max)'
              >
                {/** THIS IS A PLACEHOLDER */}
                <Grid2
                  flexDirection='row'
                  columnGap={1}
                  display='inline-flex'
                  size={7}
                >
                  <TextField disabled placeholder='Min' />
                  <TextField disabled placeholder='Max' />
                </Grid2>
              </Menu_Item_Control>
            </Grid2>
            {selected_y_properties.length > 1 && (
              <Grid2
                display='flex'
                size='grow'
                justifyContent='flex-end'
                paddingInline={1}
              >
                <Button
                  variant='text'
                  size='small'
                  startIcon={<Trash03 width={16} />}
                  onClick={() => handle_remove_property_filter(index)}
                >
                  Remove Y-Axis
                </Button>
              </Grid2>
            )}
          </>
        ))}
        {selected_y_properties.length < MAX_Y_AXIS_METRICS && (
          <Button
            variant='text'
            size='small'
            startIcon={<Plus width={16} />}
            onClick={handle_add_property_filter}
          >
            Add Y-Axis
          </Button>
        )}
      </Box>
    </>
  )
}
