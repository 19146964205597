import { Box, MenuItem, Select, SelectChangeEvent, styled } from '@mui/material'
import { ChevronDown, Palette } from '@untitled-ui/icons-react'

import { CHART_TYPE } from '../../charts/chart_options'

const OMITTED_CHART_TYPES = [CHART_TYPE.SCATTER]
const VALID_CHART_TYPES = Object.entries(CHART_TYPE).filter(
  ([, chart_type]) => !OMITTED_CHART_TYPES.includes(chart_type)
)
const CHART_TYPE_NAME_MAP: Partial<Record<CHART_TYPE, string>> = {
  [CHART_TYPE.LINE]: 'Line',
  [CHART_TYPE.LINE_MARKER]: 'Scatterplot'
}

type Chart_Type_Select_Props = {
  selected_chart_type: CHART_TYPE
  on_selected_chart_type_change: (e: SelectChangeEvent) => void
}
export function Chart_Type_Select(props: Chart_Type_Select_Props) {
  const { selected_chart_type, on_selected_chart_type_change } = props

  return (
    <Select
      sx={{
        border: 0,
        boxShadow: 'none',
        width: '100%',
        padding: 0,
        '& .MuiSelect-icon': {
          top: 'auto',
          color: theme => theme.palette.gray['500'],
          width: '1rem',
          height: '1rem'
        },
        '& .MuiSelect-select': {
          paddingInline: theme => theme.spacing(1)
        }
      }}
      value={selected_chart_type}
      IconComponent={ChevronDown}
      renderValue={value => {
        return (
          <Select_Value>
            <Palette width={18} height={18} />
            {CHART_TYPE_NAME_MAP[value]}
          </Select_Value>
        )
      }}
      onChange={on_selected_chart_type_change}
      MenuProps={{
        slotProps: { paper: { elevation: 4 } }
      }}
    >
      {VALID_CHART_TYPES.map(([key, value]) => (
        <Menu_Item_Label
          key={key}
          value={value}
          selected={selected_chart_type === value}
        >
          {CHART_TYPE_NAME_MAP[value]}
        </Menu_Item_Label>
      ))}
    </Select>
  )
}

const Menu_Item_Label = styled(MenuItem)(({ theme }) => ({
  fontWeight: 500,
  fontSize: theme.typography.sm,
  paddingBlock: theme.spacing(1.25),
  '&.Mui-selected': {
    backgroundColor: theme.palette.grey[100],
    '&:hover,&:focus': {
      backgroundColor: theme.palette.grey[200]
    }
  }
}))

const Select_Value = styled(Box)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 700,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '0.5rem',
  flex: 1,
  fontSize: theme.typography.sm
}))
