import { useQuery } from '@apollo/client'
import { Box, Grid2 } from '@mui/material'

import { Filter_Option } from '../../../../gql_generated/graphql'
import { use_workspace_and_org_ids } from '../../../../navigation/hooks/use_workspace_and_org_ids'
import {
  use_app_dispatch,
  use_app_selector
} from '../../../../state/redux/hooks'
import { Autocomplete_Filter } from '../../../controls/filters/Autocomplete_Filter'
import Numeric_Filter from '../../../controls/filters/Numeric_Filter'
import {
  Menu_Header,
  Menu_Item_Control,
  use_menu_nav
} from '../../../controls/menus'
import { select_staged_dataset_table_rows } from '../../../insights_slice'
import {
  select_property_filter_keys,
  select_selected_x_observation_property,
  select_x_property_range,
  set_selected_x_observation_property,
  set_x_property_range
} from '../cycler_observations_chart_slice'
import { GET_CYCLE_OBSERVATION_FILTER_CONFIGS } from '../queries/get_cycler_observation_filter_configs'

export function In_Cycle_Panel_X_Axis() {
  const { return_to_root } = use_menu_nav()
  const { organization_id, workspace_id } = use_workspace_and_org_ids()
  const dispatch = use_app_dispatch()

  const additional_filter_keys = use_app_selector(select_property_filter_keys)
  const staged_dataset_table_rows = use_app_selector(
    select_staged_dataset_table_rows
  )
  const selected_x_observation_property = use_app_selector(
    select_selected_x_observation_property
  )
  const selected_x_property_range = use_app_selector(select_x_property_range)

  function handle_selected_x_observation_property_change(
    _e: any,
    selected_x_observation_property: Filter_Option
  ) {
    dispatch(
      set_selected_x_observation_property(selected_x_observation_property)
    )
  }

  function handle_x_property_range_change(
    new_range: [number | null, number | null]
  ) {
    dispatch(set_x_property_range(new_range))
  }

  const { data, loading } = useQuery(GET_CYCLE_OBSERVATION_FILTER_CONFIGS, {
    variables: {
      organization_id: organization_id as string,
      workspace_ids: [workspace_id as string],
      dataset_ids: (staged_dataset_table_rows || []).map(row => row.id),
      dataset_filters: [],
      additional_filter_keys
    },
    skip: !organization_id || !workspace_id
  })

  const observation_property_options =
    data?.get_observation_filter_configs?.find(
      property => property.filter_property === 'plottable_observation_property'
    )?.options || []

  return (
    <>
      <Menu_Header title='X-Axis' on_go_back={return_to_root} />
      <Box width={400} paddingBottom={2}>
        <Grid2 container paddingBlock={1} paddingInline={1} rowGap={1}>
          <Menu_Item_Control label='X-Axis' input_id='x-axis-type' emphasize>
            <Autocomplete_Filter
              id='x-axis-type'
              aria-labelledby='x-axis-type'
              disableClearable={true}
              grid_item_size={7}
              loading={loading}
              multiple={false}
              onChange={handle_selected_x_observation_property_change}
              options={observation_property_options}
              placeholder='Add Metric'
              value={selected_x_observation_property}
            />
          </Menu_Item_Control>
          <Menu_Item_Control
            label='Normalize by'
            input_id='x-axis-normalize-by'
          >
            {/** THIS IS A PLACEHOLDER */}
            <Autocomplete_Filter
              id='x-axis-normalize-by'
              aria-labelledby='x-axis-normalize-by'
              disableClearable
              disabled
              grid_item_size={7}
              loading={loading}
              multiple={false}
              onChange={handle_selected_x_observation_property_change}
              options={[]}
              placeholder='Add Metric'
              value={undefined}
            />
          </Menu_Item_Control>
          <Menu_Item_Control
            label='Limits (Min - Max)'
            input_id='x-axis-limits'
          >
            <Grid2
              flexDirection='row'
              columnGap={1}
              display='inline-flex'
              size={7}
            >
              <Numeric_Filter
                placeholder='X-Axis min'
                onBlur={event => {
                  const _parsedValue = parseFloat(event.target.value)

                  handle_x_property_range_change([
                    _parsedValue || null,
                    selected_x_property_range?.[1] || null
                  ])
                }}
                value={selected_x_property_range?.[0] ?? undefined}
              />

              <Numeric_Filter
                placeholder='X-Axis max'
                onBlur={event => {
                  const _parsedValue = parseFloat(event.target.value)

                  handle_x_property_range_change([
                    selected_x_property_range?.[0] || null,
                    _parsedValue || null
                  ])
                }}
                value={selected_x_property_range?.[1] ?? undefined}
              />
            </Grid2>
          </Menu_Item_Control>
        </Grid2>
      </Box>
    </>
  )
}
