import { DEFAULT_ICON_COLOR } from './constants'

interface Marker_Icon_Props {
  marker: MarkerShapeOptions
  color?: string
}

export const Marker_Icon = ({
  marker,
  color = DEFAULT_ICON_COLOR
}: Marker_Icon_Props) => {
  const size = 10
  const half = size / 2

  const renderMarker = () => {
    switch (marker) {
      case 'circle':
        return <circle cx={half} cy={half} r={half} fill={color} />
      case 'square':
      case 'rect':
        return <rect x='0' y='0' width={size} height={size} fill={color} />
      case 'x':
      case 'X':
        return (
          <>
            <line
              x1='0'
              y1='0'
              x2={size}
              y2={size}
              stroke={color}
              strokeWidth='2'
            />
            <line
              x1='0'
              y1={size}
              x2={size}
              y2='0'
              stroke={color}
              strokeWidth='2'
            />
          </>
        )
      case 'plus':
      case '+':
        return (
          <>
            <line
              x1={half}
              y1='0'
              x2={half}
              y2={size}
              stroke={color}
              strokeWidth='2'
            />
            <line
              x1='0'
              y1={half}
              x2={size}
              y2={half}
              stroke={color}
              strokeWidth='2'
            />
          </>
        )
    }
  }

  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
      {renderMarker()}
    </svg>
  )
}
