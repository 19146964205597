import { useQuery } from '@apollo/client'
import { Box, Grid2 } from '@mui/material'

import { use_workspace_and_org_ids } from '../../../../navigation/hooks/use_workspace_and_org_ids'
import {
  use_app_dispatch,
  use_app_selector
} from '../../../../state/redux/hooks'
import { Autocomplete_Filter } from '../../../controls/filters/Autocomplete_Filter'
import {
  Menu_Header,
  Menu_Item_Control,
  use_menu_nav
} from '../../../controls/menus'
import { Selector_Option } from '../../../types'
import { CHART_AGGREGATION_TYPES } from '../../chart_options'
import {
  select_group_by_property_options,
  select_selected_aggregate_by_property,
  select_selected_group_by_property,
  set_property_options,
  set_selected_aggregate_by_property,
  set_selected_group_by_property
} from '../cycle_summaries_chart_slice'
import { GET_CYCLE_SUMMARY_PROPERTIES } from '../queries/get_cycle_summary_properties'

export function Cycle_Panel_Group_By() {
  const { return_to_root } = use_menu_nav()
  const dispatch = use_app_dispatch()
  const { organization_id, workspace_id } = use_workspace_and_org_ids()

  const group_by_property_options = use_app_selector(
    select_group_by_property_options
  )

  const { loading } = useQuery(GET_CYCLE_SUMMARY_PROPERTIES, {
    variables: {
      organization_id: organization_id as string
    },
    skip: !organization_id || !workspace_id,
    onCompleted: data => {
      dispatch(
        set_property_options({
          cycle_summary_properties:
            data.get_cycle_summary_properties.cycle_summary_properties,
          dataset_properties:
            data.get_cycle_summary_properties.dataset_properties
        })
      )
    }
  })

  function handle_selected_group_by_property_change(
    _e: any,
    selected_group_by_property: Selector_Option | null
  ) {
    dispatch(set_selected_group_by_property(selected_group_by_property))
  }

  function handle_selected_aggregate_by_property_change(
    _e: any,
    selected_aggregate_by_property: Selector_Option
  ) {
    dispatch(set_selected_aggregate_by_property(selected_aggregate_by_property))
  }

  const selected_group_by_property = use_app_selector(
    select_selected_group_by_property
  )

  const selected_aggregate_by_property = use_app_selector(
    select_selected_aggregate_by_property
  )

  return (
    <>
      <Menu_Header title='Group by' on_go_back={return_to_root} />
      <Box width={400} paddingBottom={2}>
        <Grid2 container padding={1} paddingBottom={2} rowGap={1} width={400}>
          <Menu_Item_Control label='Group by' input_id='group-by' emphasize>
            <Autocomplete_Filter
              id='group-by'
              aria-labelledby='group-by'
              grid_item_size={7}
              loading={loading}
              options={group_by_property_options}
              value={selected_group_by_property}
              onChange={handle_selected_group_by_property_change}
              disableClearable={false}
              multiple={false}
              placeholder='Select Metric'
            />
          </Menu_Item_Control>
          <Menu_Item_Control label='Aggregation' input_id='chart-agg' emphasize>
            <Autocomplete_Filter
              id='chart-agg'
              aria-labelledby='chart-agg'
              grid_item_size={7}
              loading={loading}
              options={CHART_AGGREGATION_TYPES}
              value={selected_aggregate_by_property}
              onChange={handle_selected_aggregate_by_property_change}
              disableClearable={true}
              disabled={selected_group_by_property == null}
              multiple={false}
              placeholder='None'
            />
          </Menu_Item_Control>
        </Grid2>
      </Box>
    </>
  )
}
