import { Menu_Nav_Provider, Menu_Panel } from '../../../controls/menus'
import { Menu_Panel_Id } from '../../../controls/menus/constants'
import { Menu_Nav_Props } from '../../../controls/menus/types'
import { Dataset_Metrics_Panel_Root } from './Dataset_Metrics_Panel_Root'
import { Dataset_Metrics_Panel_X_Axis } from './Dataset_Metrics_Panel_X_Axis'
import { Dataset_Metrics_Panel_Y_Axis } from './Dataset_Metrics_Panel_Y_Axis'

export function Dataset_Metrics_Chart_Menu_Nav(props: Menu_Nav_Props) {
  const { chart_title = 'Dataset Metrics Chart', popover_ref } = props

  return (
    <Menu_Nav_Provider on_panel_change={popover_ref?.current?.updatePosition}>
      <Menu_Panel panel_id={Menu_Panel_Id.ROOT}>
        <Dataset_Metrics_Panel_Root chart_title={chart_title} />
      </Menu_Panel>
      <Menu_Panel panel_id={Menu_Panel_Id.X_AXIS}>
        <Dataset_Metrics_Panel_X_Axis />
      </Menu_Panel>
      <Menu_Panel panel_id={Menu_Panel_Id.Y_AXIS}>
        <Dataset_Metrics_Panel_Y_Axis />
      </Menu_Panel>
    </Menu_Nav_Provider>
  )
}
