import { Grid2, PopoverActions } from '@mui/material'
import { DotsHorizontal } from '@untitled-ui/icons-react'
import { useRef } from 'react'

import { Popover } from '../../../components'
import { use_app_selector } from '../../../state/redux/hooks'
import { Cycle_Chart_Menu_Nav } from '../../charts/cycle_summaries/controls'
import { In_Cycle_Chart_Menu_Nav } from '../../charts/cycler_observations/controls'
import { Dataset_Metrics_Chart_Menu_Nav } from '../../charts/dataset_metrics/controls'
import {
  Chart_Views,
  select_active_chart,
  select_charts
} from '../../insights_slice'

export const Insights_Filter_Controls = () => {
  const active_chart = use_app_selector(select_active_chart)
  const charts = use_app_selector(select_charts)
  const active_chart_type = charts[active_chart].chart_view

  // We have this so we can use popover actions to adjust position
  // of the popover when child components have different sizes
  const popoverActions = useRef<PopoverActions>(null)

  return (
    <Grid2 container justifyContent='flex-end'>
      <Popover
        show_close_icon
        trigger_contents={<DotsHorizontal height={24} width={24} />}
        trigger_props={{
          sx: {
            color: theme => theme.palette.gray['500'],
            borderRadius: '100%',
            minWidth: '2.5rem',
            minHeight: '2.5rem'
          }
        }}
        popover_contents={
          <>
            <div hidden={active_chart_type !== Chart_Views.CYCLER_SERIES_CHART}>
              <In_Cycle_Chart_Menu_Nav
                chart_title='Chart Configuration'
                popover_ref={popoverActions}
              />
            </div>
            <div hidden={active_chart_type !== Chart_Views.CYCLER_CYCLE_CHART}>
              <Cycle_Chart_Menu_Nav
                chart_title='Chart Configuration'
                popover_ref={popoverActions}
              />
            </div>
            <div
              hidden={active_chart_type !== Chart_Views.DATASET_METRICS_CHART}
            >
              <Dataset_Metrics_Chart_Menu_Nav
                chart_title='Chart Configuration'
                popover_ref={popoverActions}
              />
            </div>
          </>
        }
        popover_props={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'right'
          },
          action: popoverActions
        }}
      />
    </Grid2>
  )
}
