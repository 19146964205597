import { Menu_Nav_Provider, Menu_Panel } from '../../../controls/menus'
import { Menu_Panel_Id } from '../../../controls/menus/constants'
import { Menu_Nav_Props } from '../../../controls/menus/types'
import { Cycle_Panel_Filters } from './Cycle_Panel_Filters'
import { Cycle_Panel_Group_By } from './Cycle_Panel_Group_By'
import { Cycle_Panel_Root } from './Cycle_Panel_Root'
import { Cycle_Panel_X_Axis } from './Cycle_Panel_X_Axis'
import { Cycle_Panel_Y_Axis } from './Cycle_Panel_Y_Axis'

export function Cycle_Chart_Menu_Nav(props: Menu_Nav_Props) {
  const { chart_title = 'Cycle Chart', popover_ref } = props

  return (
    <Menu_Nav_Provider on_panel_change={popover_ref?.current?.updatePosition}>
      <Menu_Panel panel_id={Menu_Panel_Id.ROOT}>
        <Cycle_Panel_Root chart_title={chart_title} />
      </Menu_Panel>
      <Menu_Panel panel_id={Menu_Panel_Id.X_AXIS}>
        <Cycle_Panel_X_Axis />
      </Menu_Panel>
      <Menu_Panel panel_id={Menu_Panel_Id.Y_AXIS}>
        <Cycle_Panel_Y_Axis />
      </Menu_Panel>
      <Menu_Panel panel_id={Menu_Panel_Id.GROUP_BY}>
        <Cycle_Panel_Group_By />
      </Menu_Panel>
      <Menu_Panel panel_id={Menu_Panel_Id.CYCLE_METRICS_FILTERS}>
        <Cycle_Panel_Filters />
      </Menu_Panel>
    </Menu_Nav_Provider>
  )
}
