import { InputAdornment, TextField } from '@mui/material'
import { SearchLg } from '@untitled-ui/icons-react'
import { useDebounceCallback } from 'usehooks-ts'

type Search_Input_Props = {
  value?: string
  on_change: (value: string) => void
  default_value?: string
}

export function Search_Input(props: Search_Input_Props) {
  const { on_change, default_value = '' } = props
  const debounced_on_change = useDebounceCallback(on_change, 300)

  return (
    <TextField
      placeholder='Search'
      hiddenLabel
      slotProps={{
        input: {
          startAdornment: (
            <InputAdornment position='start'>
              <SearchLg width={18} height={18} />
            </InputAdornment>
          )
        }
      }}
      sx={{ width: '100%' }}
      defaultValue={default_value}
      onChange={e => debounced_on_change(e.target.value)}
    />
  )
}
