import { useQuery } from '@apollo/client'
import { Box, Button, Divider, Grid2 } from '@mui/material'
import { Plus, Trash03, XClose } from '@untitled-ui/icons-react'

import {
  Filter_Option,
  Filter_Type,
  Property_Type
} from '../../../../gql_generated/graphql'
import { use_workspace_and_org_ids } from '../../../../navigation/hooks/use_workspace_and_org_ids'
import {
  use_app_dispatch,
  use_app_selector
} from '../../../../state/redux/hooks'
import { Autocomplete_Filter_Pair } from '../../../controls/filters/Autocomplete_Filter_Pair'
import { Filter_Grid_Item } from '../../../controls/filters/Filter_Grid'
import { Menu_Header, use_menu_nav } from '../../../controls/menus'
import { Rounded_Icon_Button } from '../../../controls/menus/shared_styled_components'
import { Numeric_Property_Types } from '../../chart_options'
import {
  Byterat_Property,
  Excluded_Byterat_Property
} from '../../models/byterat_properties.model'
import {
  create_property_filter,
  delete_all_property_filters,
  delete_property_filter,
  select_property_filter_keys,
  select_property_filters,
  set_property_filter_operator,
  set_property_filter_property,
  set_property_filter_values
} from '../cycle_summaries_chart_slice'
import { GET_CYCLE_SUMMARY_PROPERTIES } from '../queries/get_cycle_summary_properties'

export function Cycle_Panel_Filters() {
  const { return_to_root } = use_menu_nav()
  const dispatch = use_app_dispatch()
  const { organization_id, workspace_id } = use_workspace_and_org_ids()

  const additional_filter_keys = use_app_selector(select_property_filter_keys)
  const property_filters = use_app_selector(select_property_filters)

  const { data, loading } = useQuery(GET_CYCLE_SUMMARY_PROPERTIES, {
    variables: {
      organization_id: organization_id as string
    },
    skip: !organization_id || !workspace_id
  })

  const additional_property_key_options = additional_filter_keys.reduce(
    (acc: Record<string, Filter_Option[]>, property_key) => {
      // TODO - Support non-numeric filter types
      acc[property_key] = []
      return acc
    },
    {}
  )
  const available_property_key_options =
    data?.get_cycle_summary_properties?.cycle_summary_properties || []

  const handle_add_property_filter = () => {
    dispatch(create_property_filter())
  }
  const handle_delete_property_filter = (filter_id: string) => {
    dispatch(delete_property_filter({ filter_id }))
  }
  const handle_property_filter_property_change = (
    filter_id: string,
    property: Filter_Option | null,
    filter_type?: Filter_Type
  ) => {
    dispatch(set_property_filter_property({ filter_id, property, filter_type }))
  }
  const handle_property_filter_values_change = (
    filter_id: string,
    values: Filter_Option[]
  ) => {
    dispatch(set_property_filter_values({ filter_id, values }))
  }
  const handle_delete_all_property_filters = () => {
    dispatch(delete_all_property_filters())
  }
  const handle_property_operator_change = (payload: {
    filter_id: string
    filter_type: Filter_Type
  }) => {
    dispatch(set_property_filter_operator(payload))
  }

  return (
    <>
      <Menu_Header title='Filters' on_go_back={return_to_root} />
      <Box width={500}>
        <Grid2 container padding={1} paddingBottom={2} rowGap={1}>
          <Grid2
            container
            justifyContent='flex-start'
            alignItems='flex-start'
            marginLeft='auto'
            gap={2}
            size={12}
          >
            <Filter_Grid_Item
              container
              flexDirection='column'
              alignItems='flex-start'
              width='100%'
              gap={1}
            >
              {Object.entries(property_filters).map(([key, filter]) => {
                const selected_property_key_options = filter.property
                  ? additional_property_key_options[filter.property.key]
                  : []

                return (
                  <Filter_Grid_Item
                    container
                    key={key}
                    alignItems='center'
                    width='100%'
                  >
                    <Autocomplete_Filter_Pair
                      left_filter={{
                        value: filter.property,
                        options: available_property_key_options.filter(
                          _option => {
                            // TODO - Support non-numeric filter types
                            if (
                              !Numeric_Property_Types.includes(
                                _option?.type as Property_Type
                              )
                            ) {
                              return false
                            }

                            // TODO - hardcoded list of exclusions, until we have a way to filter by date/time properties
                            return !Excluded_Byterat_Property.includes(
                              _option.key as Byterat_Property
                            )
                          }
                        ),
                        placeholder: 'Select Filter Property',
                        multiple: false,
                        disableClearable: false,
                        onChange: (_e, value) =>
                          handle_property_filter_property_change(key, value),
                        sx: {
                          '& .MuiInputBase-root': {
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0
                          }
                        }
                      }}
                      center_operator={{
                        onChange: filter_type => {
                          handle_property_operator_change({
                            filter_id: key,
                            filter_type
                          })
                        },
                        sx: {
                          '& .MuiInputBase-root': {
                            borderRadius: 0
                          }
                        }
                      }}
                      right_filter={{
                        value: filter.values,
                        options: selected_property_key_options,
                        placeholder: 'Select Filter Values',
                        multiple: true,
                        disableClearable: false,
                        onChange: (_e, value) =>
                          handle_property_filter_values_change(key, value),
                        // type: Property_Type.Float,
                        type: filter?.property?.type as Property_Type,
                        sx: {
                          '& .MuiInputBase-root': {
                            borderRadius: 0
                          }
                        }
                      }}
                      filter_type={filter.filter_type}
                      // filter_type={Filter_Type.NumericEquals}
                      loading={loading}
                    />
                    <Box
                      flexDirection='column'
                      display='flex'
                      alignSelf='stretch'
                    >
                      <Rounded_Icon_Button
                        size='small'
                        onClick={() => handle_delete_property_filter(key)}
                        sx={{
                          flex: 'auto',
                          borderTopLeftRadius: 0,
                          borderTopRightRadius: 6,
                          borderBottomLeftRadius: 0,
                          borderBottomRightRadius: 6,
                          border: theme => `1px solid ${theme.palette.divider}`
                        }}
                      >
                        <XClose />
                      </Rounded_Icon_Button>
                    </Box>
                  </Filter_Grid_Item>
                )
              })}
              <Button
                variant='text'
                size='small'
                startIcon={<Plus width='1em' height='1em' />}
                onClick={handle_add_property_filter}
              >
                Add Filter
              </Button>
              {Object.keys(property_filters).length > 0 && (
                <>
                  <Divider />
                  <Button
                    variant='text'
                    size='small'
                    startIcon={<Trash03 width='1em' height='1em' />}
                    onClick={handle_delete_all_property_filters}
                  >
                    Remove All
                  </Button>
                </>
              )}
            </Filter_Grid_Item>
          </Grid2>
        </Grid2>
      </Box>
    </>
  )
}
