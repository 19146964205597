import { ReactNode, useLayoutEffect, useRef, useState } from 'react'
import { styled } from '@mui/material'

interface Dynamic_Column_Grid_Props {
  children: ReactNode
  full_width?: boolean
}

export const Dynamic_Column_Grid = ({
  children,
  full_width
}: Dynamic_Column_Grid_Props) => {
  const container_ref = useRef<HTMLDivElement>(null)
  const [min_width, set_min_width] = useState(200)

  // Get the maximum width of an item in the grid and set its width as
  // the minimum column width. A useLayoutEffect is used to ensure this
  // occurs before the DOM is painted.
  useLayoutEffect(() => {
    const update_min_width = () => {
      if (container_ref.current) {
        const grid_items = container_ref.current.children
        let max_item_width = 0
        Array.from(grid_items).forEach(item => {
          const item_width = (item as HTMLElement).offsetWidth
          if (item_width > max_item_width) {
            max_item_width = item_width
          }
        })
        set_min_width(max_item_width)
      }
    }

    update_min_width()
  }, [children])

  return (
    <Grid_Container
      ref={container_ref}
      min_width={min_width}
      full_width={full_width}
    >
      {children}
    </Grid_Container>
  )
}

interface Grid_Container_Props {
  min_width: number
  full_width?: boolean
}
const Grid_Container = styled('div')<Grid_Container_Props>`
  width: 100%;
  display: grid;
  grid-template-columns: ${({ min_width }) =>
    `repeat(auto-fill, minmax(${min_width}px, 1fr))`};
`
