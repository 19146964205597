import { useQuery } from '@apollo/client'
import styled from '@emotion/styled'
import { Grid2 } from '@mui/material'
import { memo } from 'react'
import Apex_Chart from 'react-apexcharts'

import { use_workspace_and_org_ids } from '../../../navigation/hooks/use_workspace_and_org_ids'
import { use_app_dispatch, use_app_selector } from '../../../state/redux/hooks'
import { select_selected_dataset_ids } from '../../insights_slice'
import { OnAxesRangeChange } from '../chart_options'
import { Chart_Legend } from '../legends/Chart_Legend'
import { Centered_Loading_Overlay } from '../loading/Centered_Loading_Overlay'
import {
  select_apex_chart_options,
  select_chart_data,
  select_chart_type,
  select_filters_for_query,
  select_selected_cycle_numbers,
  select_selected_x_observation_property,
  select_selected_y_observation_properties,
  select_series_groups,
  select_y_axes,
  set_chart_ranges,
  set_cycler_observations,
  set_y_property_ranges
} from './cycler_observations_chart_slice'
import { GET_CYCLER_OBSERVATIONS } from './queries/get_cycler_observations'

const Apex_Chart_Controller = memo(() => {
  const dispatch = use_app_dispatch()

  const handle_zoom_change = (
    x_range: [number | null, number | null],
    y_ranges: Record<string, [number | null, number | null]> | null
  ) => {
    dispatch(set_chart_ranges({ x_range, y_ranges }))
  }

  const handle_axes_range_update = ({
    x_axis_range,
    y_axes_ranges,
    x_axis_type,
    y_axes_types
  }: OnAxesRangeChange) => {
    const _new_y_property_ranges: {
      [key: number]: [number | null, number | null]
    } = {}
    Object.keys(y_axes_ranges).forEach(axisIndex => {
      const range = y_axes_ranges[Number(axisIndex)]

      _new_y_property_ranges[Number(axisIndex)] = range
    })

    dispatch(set_y_property_ranges(_new_y_property_ranges))
  }

  const { options } = use_app_selector(state =>
    select_apex_chart_options(
      state,
      handle_zoom_change,
      handle_axes_range_update
    )
  )

  const chart_data = use_app_selector(select_chart_data)

  return (
    <div className='apex_chart_controller'>
      <Apex_Chart
        key={options.xaxis?.type}
        options={options}
        series={chart_data}
        type='line'
        height='440px'
        width='100%'
      />
    </div>
  )
})

export const Cycler_Observations_Chart = () => {
  const { organization_id, workspace_id } = use_workspace_and_org_ids()
  const dispatch = use_app_dispatch()

  const selected_dataset_ids = use_app_selector(select_selected_dataset_ids)
  const selected_cycle_numbers = use_app_selector(select_selected_cycle_numbers)
  const filters = use_app_selector(select_filters_for_query)
  const selected_y_observation_properties = use_app_selector(
    select_selected_y_observation_properties
  )
  const selected_x_observation_property = use_app_selector(
    select_selected_x_observation_property
  )
  const series_groups = use_app_selector(select_series_groups)
  const y_axes = use_app_selector(select_y_axes)
  const chart_type = use_app_selector(select_chart_type)

  // Fetch data and add results to slice
  const { loading } = useQuery(GET_CYCLER_OBSERVATIONS, {
    variables: {
      dataset_ids: selected_dataset_ids,
      cycles: selected_cycle_numbers,
      organization_id: organization_id as string,
      workspace_ids: [workspace_id as string],
      dataset_filters: filters,
      x_property: selected_x_observation_property?.key.toString(),
      y_properties: selected_y_observation_properties?.map(select =>
        select.key.toString()
      ),
      additional_properties: ['mode']
    },
    onCompleted(data) {
      dispatch(set_cycler_observations(data.get_cycler_observations))
    },
    skip: !organization_id || !workspace_id
  })

  return (
    <Cycler_Series_Chart_Container container>
      <Chart_Legend
        series_groups={series_groups}
        y_axes={y_axes}
        chart_type={chart_type}
      />
      <Grid2 size={{ xs: 12 }}>
        {loading && <Centered_Loading_Overlay />}
        <Apex_Chart_Controller />
      </Grid2>
    </Cycler_Series_Chart_Container>
  )
}

const Cycler_Series_Chart_Container = styled(Grid2)`
  .apex_chart_controller {
    display: block;
    width: 100%;
    padding-left: 1.5rem;
    padding-right: 2.375rem;
  }
`
