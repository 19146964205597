import { Box, Grid2, TextField } from '@mui/material'

import { Filter_Option } from '../../../../gql_generated/graphql'
import {
  use_app_dispatch,
  use_app_selector
} from '../../../../state/redux/hooks'
import { Autocomplete_Filter } from '../../../controls/filters/Autocomplete_Filter'
import {
  Menu_Header,
  Menu_Item_Control,
  use_menu_nav
} from '../../../controls/menus'
import {
  select_metric_options,
  select_selected_x_property,
  set_selected_x_property
} from '../dataset_metrics_chart_slice'

export function Dataset_Metrics_Panel_X_Axis() {
  const { return_to_root } = use_menu_nav()
  const dispatch = use_app_dispatch()

  const metric_options = use_app_selector(select_metric_options)
  const selected_x_property = use_app_selector(select_selected_x_property)

  const handle_selected_x_property_change = (
    _e: any,
    selected_x_property: Filter_Option | null
  ) => {
    dispatch(set_selected_x_property(selected_x_property))
  }

  return (
    <>
      <Menu_Header title='X-Axis' on_go_back={return_to_root} />
      <Box width={400} paddingBottom={2}>
        <Grid2 container paddingBlock={1} paddingInline={1} rowGap={1}>
          <Menu_Item_Control label='X-Axis' input_id='x-axis-type' emphasize>
            <Autocomplete_Filter
              id='x-axis-type'
              aria-labelledby='x-axis-type'
              grid_item_size={7}
              multiple={false}
              onChange={handle_selected_x_property_change}
              options={metric_options}
              placeholder='Add Metric'
              // @ts-ignore
              value={selected_x_property}
            />
          </Menu_Item_Control>
          <Menu_Item_Control
            label='Normalize by'
            input_id='x-axis-normalize-by'
          >
            {/** THIS IS A PLACEHOLDER */}
            <Autocomplete_Filter
              id='x-axis-normalize-by'
              aria-labelledby='x-axis-normalize-by'
              disableClearable
              disabled
              grid_item_size={7}
              multiple={false}
              onChange={console.log}
              options={[]}
              placeholder='Normalize by...'
              value={undefined}
            />
          </Menu_Item_Control>
          <Menu_Item_Control
            label='Limits (Min - Max)'
            input_id='x-axis-limits'
          >
            {/** THIS IS A PLACEHOLDER */}
            <Grid2
              flexDirection='row'
              columnGap={1}
              display='inline-flex'
              size={7}
            >
              <TextField disabled placeholder='Min' />
              <TextField disabled placeholder='Max' />
            </Grid2>
          </Menu_Item_Control>
        </Grid2>
      </Box>
    </>
  )
}
