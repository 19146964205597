import { gql } from '../../gql_generated'

export const GET_DATASETS_FOR_INSIGHTS_DASHBOARD = gql(`
  query get_datasets_for_insights_dashboard($organization_id: String!, $workspace_ids: [String!]!, $page_from: Float!, $page_size: Float!, $query_string: String, $properties_sort: [Property_Sort!]!, $properties_filter: Property_Filters) {
    get_datasets_list(
      organization_id: $organization_id
      workspace_ids: $workspace_ids
      page_from: $page_from
      page_size: $page_size
      query_string: $query_string 
      properties_sort: $properties_sort
      properties_filter: $properties_filter
    ) {
      result_count
      page_size
      page_from
      rows {
        id
        properties {
          key
          label
          value
        }
      }
    }
  }
`)

export const GET_COLUMNS_FOR_INSIGHTS_DASHBOARD = gql(`
  query get_columns_for_insights_dashboard($organization_id: String!, $workspace_ids: [String!]!) {
    get_datasets_list(
      organization_id: $organization_id
      workspace_ids: $workspace_ids
      page_from: 0
      page_size: 1
    ) {
      columns{
        key
        pinned
        property_type
        units
      }
    }
  }
`)
