import {
  GridColDef,
  GridColType,
  GridFilterModel,
  GridSortModel,
  GridValidRowModel
} from '@mui/x-data-grid'
import { PayloadAction, createSelector } from '@reduxjs/toolkit'

import {
  Dataset_Column,
  Filter_Logic_Operator,
  Filter_Option,
  Filter_Type,
  Property_Filters,
  Property_Sort,
  Property_Type,
  Sort_Direction
} from '../gql_generated/graphql'
import { create_app_slice } from '../state/redux/create_app_slice'
import { reset_insights } from '../state/redux/store'

export enum Chart_Views {
  CYCLER_SERIES_CHART = 'cycler_series_chart',
  CYCLER_CYCLE_CHART = 'cycler_cycle_chart',
  DATASET_METRICS_CHART = 'dataset_metrics_chart'
}

const DATASET_TABLE_ROW_PROPERTY_DENY_LIST = ['id']

export const FIXED_COLUMN_PROPERTIES: GridColDef<GridValidRowModel>[] = [
  {
    field: 'dataset_key',
    headerName: 'Dataset Name',
    width: 150,
    type: 'string'
  },
  {
    field: 'cycle_count',
    headerName: 'Cycle Count',
    width: 150,
    type: 'number',
    filterable: true,
    sortable: true
  }
  // {
  //   field: 'created_at',
  //   headerName: 'Experiment Date',
  //   width: 150,
  //   type: 'date',
  //   filterable: false
  // }
]

const FIXED_COLUMN_FIELDS = FIXED_COLUMN_PROPERTIES.map(column => column.field)

export const PROPERTY_TYPE_TO_GRID_COL_TYPE: Record<
  Property_Type,
  GridColType
> = {
  [Property_Type.Text]: 'string',
  [Property_Type.Float]: 'number',
  [Property_Type.Date]: 'date',
  [Property_Type.Timestamp]: 'date',
  [Property_Type.Integer]: 'number',
  [Property_Type.Keyword]: 'string',
  [Property_Type.Long]: 'number',
  [Property_Type.Percent]: 'number',
  [Property_Type.String]: 'string'
}

const GRID_FILTER_OPERATOR_TO_FILTER_TYPE: Record<string, Filter_Type> = {
  isAnyOf: Filter_Type.IsAnyOf,
  contains: Filter_Type.Contains,
  endsWith: Filter_Type.EndsWith,
  startsWith: Filter_Type.StartsWith,
  equals: Filter_Type.StringEquals,
  isNotEmpty: Filter_Type.IsNotEmpty,
  isEmpty: Filter_Type.IsEmpty,
  '=': Filter_Type.NumericEquals,
  '!=': Filter_Type.NumericDoesNotEqual,
  '>': Filter_Type.GreaterThan,
  '>=': Filter_Type.GreaterThanOrEqual,
  '<': Filter_Type.LessThan,
  '<=': Filter_Type.LessThanOrEqual
}

const NULLABLE_FILTER_TYPES = [Filter_Type.IsEmpty, Filter_Type.IsNotEmpty]

interface Insights_Charts {
  /**
   * chart ids will eventually be uuids that are generated when
   * a user create a new chart views.
   */
  id: string
  chart_view: Chart_Views
  label: string
}

interface Pagination {
  from: number
  size: number
}

interface Dataset_Property_Value {
  key: string
  label: string
  value?: any
}
export interface Dataset {
  id: string
  properties?: Dataset_Property_Value[] | null
}

export interface Dataset_Grid_Row {
  id: string
  [key: string]: any
}

export interface Insights_Slice_State {
  charts: Record<string, Insights_Charts>
  active_tab: string
  staged_dataset_table_rows: Dataset_Grid_Row[]
  datasets: Dataset[]
  properties: Dataset_Column[]
  pagination: Pagination
  query_string?: string
  properties_sort: Property_Sort[]
  properties_filter?: Property_Filters
  x_property: Filter_Option | null
  y_properties: Filter_Option[]
}

const initial_state: Insights_Slice_State = {
  charts: {
    '0': {
      id: '0',
      chart_view: Chart_Views.CYCLER_CYCLE_CHART,
      label: 'Cycle Metrics'
    },
    '1': {
      id: '1',
      chart_view: Chart_Views.CYCLER_SERIES_CHART,
      label: 'In-Cycle Metrics'
    },
    '2': {
      id: '2',
      chart_view: Chart_Views.DATASET_METRICS_CHART,
      label: 'Cell Metrics'
    }
  },
  active_tab: '0',
  properties: [],
  staged_dataset_table_rows: [],
  datasets: [],
  pagination: { from: 0, size: 25 },
  properties_sort: [],
  x_property: { key: 'cycle_count', label: 'Cycle Count' },
  y_properties: [
    { key: 'discharge_capacity_cycle_1', label: 'Discharge Capacity Cycle 1' }
  ]
}

export const insights_slice = create_app_slice({
  name: 'insights',
  initialState: initial_state,
  reducers: create => ({
    add_staged_dataset_table_rows: create.reducer(
      (state, { payload }: PayloadAction<Dataset_Grid_Row[]>) => {
        state.staged_dataset_table_rows =
          state.staged_dataset_table_rows.concat(payload)
      }
    ),
    remove_staged_dataset_table_rows: create.reducer(
      (state, { payload }: PayloadAction<Dataset_Grid_Row>) => {
        state.staged_dataset_table_rows =
          state.staged_dataset_table_rows.filter(({ id }) => id !== payload.id)
      }
    ),
    clear_staged_dataset_table_rows: create.reducer(state => {
      state.staged_dataset_table_rows = []
    }),
    set_active_chart: create.reducer(
      (state, { payload }: PayloadAction<string>) => {
        state.active_tab = payload
      }
    ),
    set_pagination: create.reducer(
      (state, { payload }: PayloadAction<Pagination>) => {
        state.pagination = payload
      }
    ),
    set_query_string: create.reducer(
      (state, { payload }: PayloadAction<string | undefined>) => {
        state.query_string = payload
      }
    ),
    set_datasets: create.reducer(
      (state, { payload }: PayloadAction<Dataset[]>) => {
        state.datasets = payload
      }
    ),
    set_properties: create.reducer(
      (state, { payload }: PayloadAction<Dataset_Column[]>) => {
        state.properties = payload
      }
    ),
    set_properties_sort_from_grid_sort_model: create.reducer(
      (state, { payload }: PayloadAction<GridSortModel>) => {
        const properties_sort = payload
          .filter(
            ({ sort }) =>
              sort === Sort_Direction.Asc || sort === Sort_Direction.Desc
          )
          .map(({ field, sort }) => ({
            property: field,
            direction: sort as Sort_Direction
          }))

        state.properties_sort = properties_sort
      }
    ),
    set_properties_filter_from_grid_filter_model: create.reducer(
      (state, { payload }: PayloadAction<GridFilterModel>) => {
        const { items, logicOperator, quickFilterValues } = payload
        state.query_string =
          quickFilterValues === undefined
            ? undefined
            : quickFilterValues.join(' ')

        if (items.length === 0) {
          state.properties_filter = undefined
          return
        }

        const filters = items
          .map(({ field, value, operator }) => {
            const filter_type = GRID_FILTER_OPERATOR_TO_FILTER_TYPE[operator]

            return {
              key: field,
              filter_type,
              value
            }
          })
          .filter(({ filter_type, value }) =>
            NULLABLE_FILTER_TYPES.includes(filter_type) ? true : !!value
          )
          .filter(({ filter_type, value }) =>
            filter_type === Filter_Type.IsAnyOf ? value.length > 0 : true
          )

        state.properties_filter = {
          filters,
          logic_operator: (logicOperator ||
            Filter_Logic_Operator.And) as Filter_Logic_Operator
        }
      }
    )
  }),
  extraReducers: builder => {
    builder.addCase(reset_insights, () => initial_state)
  },
  selectors: {
    select_staged_dataset_table_rows: slice_state =>
      slice_state.staged_dataset_table_rows,
    select_selected_dataset_ids: createSelector(
      (slice_state: Insights_Slice_State) =>
        slice_state.staged_dataset_table_rows,
      staged_dataset_table_rows => {
        return staged_dataset_table_rows.map(row => row.id)
      }
    ),
    select_active_chart: slice_state => slice_state.active_tab,
    select_charts: slice_state => slice_state.charts,
    select_pagination: slice_state => slice_state.pagination,
    select_query_string: slice_state => slice_state.query_string,
    select_properties: slice_state => slice_state.properties,
    select_properties_sort: slice_state => slice_state.properties_sort,
    select_properties_filter: slice_state => slice_state.properties_filter,
    select_grid_columns: createSelector(
      (slice_state: Insights_Slice_State) => slice_state.properties,
      properties => {
        const fixed_columns = [...FIXED_COLUMN_PROPERTIES]
        const has_cycle_count = properties.some(
          property => property.key === 'cycle_count'
        )
        const cycle_count_column_index = fixed_columns.findIndex(
          column => column.field === 'cycle_count'
        )
        if (cycle_count_column_index !== -1 && !has_cycle_count) {
          fixed_columns.splice(cycle_count_column_index, 1)
        }

        return properties.length
          ? fixed_columns.concat(
              properties
                .filter(({ key }) => !FIXED_COLUMN_FIELDS.includes(key))
                .sort((a, b) => (a.pinned === b.pinned ? 0 : a.pinned ? -1 : 1))
                .map(({ key, property_type }) => {
                  const type = property_type
                    ? PROPERTY_TYPE_TO_GRID_COL_TYPE[property_type]
                    : 'string'
                  return {
                    width: 150,
                    field: key,
                    headerName: format_column_label(key),
                    valueGetter:
                      type === PROPERTY_TYPE_TO_GRID_COL_TYPE.date
                        ? value => (value !== null ? new Date(value) : null)
                        : undefined,
                    type
                  }
                })
            )
          : []
      }
    ),
    select_grid_rows: createSelector(
      [
        (slice_state: Insights_Slice_State) => slice_state.datasets,
        (slice_state: Insights_Slice_State) =>
          slice_state.staged_dataset_table_rows
      ],
      (datasets, staged_dataset_table_rows) => {
        const staged_dataset_ids = staged_dataset_table_rows.map(({ id }) => id)
        return datasets
          .filter(({ id }) => !staged_dataset_ids.includes(id))
          .map(({ id, properties }) => {
            const filtered_properties = properties?.filter(
              ({ key }) => !DATASET_TABLE_ROW_PROPERTY_DENY_LIST.includes(key)
            )

            return {
              id,
              ...filtered_properties?.reduce(
                (
                  dataset_properties: { [key: string]: any },
                  { key, value }
                ) => {
                  dataset_properties[key] = value
                  return dataset_properties
                },
                {}
              )
            }
          })
      }
    )
  }
})

// Action creators are generated for each case reducer function.
export const {
  add_staged_dataset_table_rows,
  remove_staged_dataset_table_rows,
  clear_staged_dataset_table_rows,
  set_active_chart,
  set_pagination,
  set_query_string,
  set_datasets,
  set_properties,
  set_properties_sort_from_grid_sort_model,
  set_properties_filter_from_grid_filter_model
} = insights_slice.actions

// Selectors returned by `slice.selectors` take the root state as their first argument.
export const {
  select_staged_dataset_table_rows,
  select_selected_dataset_ids,
  select_active_chart,
  select_charts,
  select_pagination,
  select_query_string,
  select_properties,
  select_properties_sort,
  select_properties_filter,
  select_grid_rows,
  select_grid_columns
} = insights_slice.selectors

// split column_name by underscores and capitalize each word
const format_column_label = (column_name: string) => {
  return column_name
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}
