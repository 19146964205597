import { Box, Divider, MenuList, SelectChangeEvent } from '@mui/material'
import {
  BarChart12,
  FilterLines,
  HorizontalBarChart01,
  Settings04 // Share03
} from '@untitled-ui/icons-react'

import {
  use_app_dispatch,
  use_app_selector
} from '../../../../state/redux/hooks'
import { Chart_Metric_Type_Select } from '../../../controls/filters/Chart_Metric_Type_Select'
import { Chart_Type_Select } from '../../../controls/filters/Chart_Type_Select'
import { Menu_Header, use_menu_nav } from '../../../controls/menus'
import { Menu_Panel_Id } from '../../../controls/menus/constants'
import {
  Menu_Item_Label,
  Menu_Item_Right,
  Section,
  Styled_ChevronRight,
  Styled_Menu_Item
} from '../../../controls/menus/shared_styled_components'
import { With_Chart_Title } from '../../../controls/menus/types'
import { CHART_TYPE } from '../../chart_options'
import {
  select_chart_type,
  select_selected_group_by_property,
  select_selected_x_cycle_summaries_property,
  select_selected_y_cycle_summaries_properties,
  set_chart_type
} from '../cycle_summaries_chart_slice'

export function Cycle_Panel_Root(props: With_Chart_Title) {
  const dispatch = use_app_dispatch()
  const { create_set_selected_panel_id } = use_menu_nav()
  const { chart_title } = props

  const selected_chart_type = use_app_selector(select_chart_type)
  const selected_x_cycle_summaries_property = use_app_selector(
    select_selected_x_cycle_summaries_property
  )
  const selected_y_cycle_summaries_properties = use_app_selector(
    select_selected_y_cycle_summaries_properties
  )
  const selected_group_by_property = use_app_selector(
    select_selected_group_by_property
  )
  function handle_selected_chart_type_change(e: SelectChangeEvent) {
    dispatch(set_chart_type(e.target.value as CHART_TYPE))
  }

  return (
    <Box width={400}>
      <MenuList>
        <Menu_Header title={chart_title} />
        <Chart_Metric_Type_Select />
        <Divider sx={{ marginBlock: 1 }} />
        <Styled_Menu_Item
          onClick={create_set_selected_panel_id(Menu_Panel_Id.CYCLE_METRICS_FILTERS)}
        >
          <Menu_Item_Label>
            <FilterLines width={18} height={18} /> Filters
          </Menu_Item_Label>
          <Menu_Item_Right>
            <Styled_ChevronRight />
          </Menu_Item_Right>
        </Styled_Menu_Item>
        <Divider />
        <Styled_Menu_Item
          onClick={create_set_selected_panel_id(Menu_Panel_Id.X_AXIS)}
        >
          <Menu_Item_Label>
            <BarChart12 width={18} height={18} /> X-Axis
          </Menu_Item_Label>
          <Menu_Item_Right>
            {selected_x_cycle_summaries_property?.label}
            <Styled_ChevronRight />
          </Menu_Item_Right>
        </Styled_Menu_Item>
        <Section>
          <Styled_Menu_Item
            onClick={create_set_selected_panel_id(Menu_Panel_Id.Y_AXIS)}
          >
            <Menu_Item_Label>
              <HorizontalBarChart01 width={18} height={18} /> Y-Axis
            </Menu_Item_Label>
            <Menu_Item_Right>
              {selected_y_cycle_summaries_properties.length > 1
                ? `${selected_y_cycle_summaries_properties.length} Items`
                : selected_y_cycle_summaries_properties[0]?.label}
              <Styled_ChevronRight />
            </Menu_Item_Right>
          </Styled_Menu_Item>
          <Styled_Menu_Item
            onClick={create_set_selected_panel_id(Menu_Panel_Id.GROUP_BY)}
          >
            <Menu_Item_Label>
              <Settings04 width={18} height={18} /> Group by
            </Menu_Item_Label>
            <Menu_Item_Right>
              {selected_group_by_property?.label}
              <Styled_ChevronRight />
            </Menu_Item_Right>
          </Styled_Menu_Item>
          <Chart_Type_Select
            on_selected_chart_type_change={handle_selected_chart_type_change}
            selected_chart_type={selected_chart_type}
          />
        </Section>
        {/* <Divider />
        <Section>
          <Styled_Menu_Item
            onClick={create_set_selected_panel_id(Menu_Panel_Id.EXPORT)}
          >
            <Menu_Item_Label>
              <Share03 width={18} height={18} /> Export
            </Menu_Item_Label>
            <Styled_ChevronRight />
          </Styled_Menu_Item>
        </Section><Styled_Menu_Item disabled>
          <Menu_Item_Label>
            <Trash03 width={18} height={18} /> Delete Chart
          </Menu_Item_Label>
        </Styled_Menu_Item> */}
      </MenuList>
    </Box>
  )
}
