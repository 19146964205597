import { useQuery } from '@apollo/client'
import { Box, Button, Grid2 } from '@mui/material'
import { Plus, Trash03 } from '@untitled-ui/icons-react'

import { Filter_Option } from '../../../../gql_generated/graphql'
import { use_workspace_and_org_ids } from '../../../../navigation/hooks/use_workspace_and_org_ids'
import {
  use_app_dispatch,
  use_app_selector
} from '../../../../state/redux/hooks'
import { Autocomplete_Filter } from '../../../controls/filters/Autocomplete_Filter'
import Numeric_Filter from '../../../controls/filters/Numeric_Filter'
import {
  Menu_Header,
  Menu_Item_Control,
  use_menu_nav
} from '../../../controls/menus'
import { select_staged_dataset_table_rows } from '../../../insights_slice'
import {
  select_property_filter_keys,
  select_selected_y_observation_properties,
  select_y_property_ranges,
  set_selected_y_observation_properties,
  set_y_property_ranges
} from '../cycler_observations_chart_slice'
import { GET_CYCLE_OBSERVATION_FILTER_CONFIGS } from '../queries/get_cycler_observation_filter_configs'

const MAX_Y_AXIS_METRICS = 2

export function In_Cycle_Panel_Y_Axis() {
  const { return_to_root } = use_menu_nav()
  const { organization_id, workspace_id } = use_workspace_and_org_ids()
  const dispatch = use_app_dispatch()

  const additional_filter_keys = use_app_selector(select_property_filter_keys)
  const staged_dataset_table_rows = use_app_selector(
    select_staged_dataset_table_rows
  )
  const selected_y_observation_properties = use_app_selector(
    select_selected_y_observation_properties
  )
  const selected_y_property_ranges = use_app_selector(select_y_property_ranges)

  function handle_selected_y_observation_property_change(
    selected_y_observation_properties: Filter_Option[]
  ) {
    dispatch(
      set_selected_y_observation_properties(selected_y_observation_properties)
    )
  }

  const handle_add_property_filter = () => {
    handle_selected_y_observation_property_change(
      selected_y_observation_properties.concat({ key: '', label: '' })
    )
  }

  const handle_remove_property_filter = (index: number) => {
    handle_selected_y_observation_property_change(
      selected_y_observation_properties.filter((_filter, i) => i !== index)
    )
  }

  const handle_set_property_filter =
    (index: number) => (_e: any, value: Filter_Option) => {
      handle_selected_y_observation_property_change(
        selected_y_observation_properties.map((filter, i) =>
          i === index ? value : filter
        )
      )
    }

  function handle_y_property_range_change(
    index: number,
    new_range: [number | null, number | null]
  ) {
    const _newRanges = { ...selected_y_property_ranges }
    _newRanges[index] = new_range

    dispatch(set_y_property_ranges(_newRanges))
  }

  const { data, loading } = useQuery(GET_CYCLE_OBSERVATION_FILTER_CONFIGS, {
    variables: {
      organization_id: organization_id as string,
      workspace_ids: [workspace_id as string],
      dataset_ids: (staged_dataset_table_rows || []).map(row => row.id),
      dataset_filters: [],
      additional_filter_keys
    },
    skip: !organization_id || !workspace_id
  })

  const observation_property_options =
    data?.get_observation_filter_configs?.find(
      property => property.filter_property === 'plottable_observation_property'
    )?.options || []

  return (
    <>
      <Menu_Header title='Y-Axis' on_go_back={return_to_root} />
      <Box width={400} paddingBottom={2} paddingInline={1}>
        {selected_y_observation_properties.map((filter_option, index) => {
          const selected_y_property_range = selected_y_property_ranges?.[index]
          return (
            <>
              <Grid2 container paddingBlock={1} paddingInline={1} rowGap={1}>
                <Menu_Item_Control
                  input_id={`y-axis-${index}-type`}
                  label={`Y-Axis ${index + 1}`}
                  emphasize
                >
                  <Autocomplete_Filter
                    id={`y-axis-${index}-type`}
                    aria-labelledby={`y-axis-${index}-type`}
                    disableClearable
                    grid_item_size={7}
                    loading={loading}
                    multiple={false}
                    onChange={handle_set_property_filter(index)}
                    options={observation_property_options}
                    placeholder='Add Metric'
                    value={filter_option}
                    getOptionDisabled={
                      selected_y_observation_properties.length > 1
                        ? (option: Filter_Option) =>
                            selected_y_observation_properties.some(
                              filter => filter.key === option.key
                            )
                        : undefined
                    }
                  />
                </Menu_Item_Control>
                <Menu_Item_Control
                  input_id={`y-axis-${index}-normalize-by`}
                  label='Normalize by'
                >
                  {/** THIS IS A PLACEHOLDER */}
                  <Autocomplete_Filter
                    id={`y-axis-${index}-normalize-by`}
                    aria-labelledby={`y-axis-${index}-normalize-by`}
                    disableClearable={true}
                    disabled
                    grid_item_size={7}
                    loading={loading}
                    multiple={false}
                    // onChange={handle_selected_x_observation_property_change}
                    options={[]}
                    placeholder='Add Metric'
                    value={undefined}
                  />
                </Menu_Item_Control>
                <Menu_Item_Control
                  input_id={`y-axis-${index}-limits`}
                  label='Limits (Min - Max)'
                >
                  <Grid2
                    flexDirection='row'
                    columnGap={1}
                    display='inline-flex'
                    size={7}
                  >
                    <Numeric_Filter
                      placeholder='Min'
                      onBlur={event => {
                        const _parsedValue = parseFloat(event.target.value)

                        handle_y_property_range_change(index, [
                          _parsedValue || null,
                          selected_y_property_range?.[1] || null
                        ])
                      }}
                      value={selected_y_property_range?.[0] ?? undefined}
                    />

                    <Numeric_Filter
                      placeholder='Max'
                      onBlur={event => {
                        const _parsedValue = parseFloat(event.target.value)

                        if (!isNaN(_parsedValue)) {
                          handle_y_property_range_change(index, [
                            selected_y_property_range?.[0] || 0,
                            _parsedValue
                          ])
                        }
                      }}
                      value={selected_y_property_range?.[1] ?? undefined}
                    />
                  </Grid2>
                </Menu_Item_Control>
              </Grid2>
              {selected_y_observation_properties.length > 1 && (
                <Grid2
                  display='flex'
                  size='grow'
                  justifyContent='flex-end'
                  paddingInline={1}
                >
                  <Button
                    variant='text'
                    size='small'
                    startIcon={<Trash03 width={16} />}
                    onClick={() => handle_remove_property_filter(index)}
                  >
                    Remove Y-Axis
                  </Button>
                </Grid2>
              )}
            </>
          )
        })}
        {selected_y_observation_properties.length < MAX_Y_AXIS_METRICS && (
          <Button
            variant='text'
            size='small'
            startIcon={<Plus width={16} />}
            onClick={handle_add_property_filter}
          >
            Add Y-Axis
          </Button>
        )}
      </Box>
    </>
  )
}
